import React, { useState, useEffect } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Breadcrumbs } from '../../../elements/Breadcrumbs/Breadcrumbs';
import Container from '../../../layout/Container/Container';
import { Button } from '../../../ui/Button/Button';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './Logout.module.scss';

export default withStoreConfiguration(({ storeConfiguration, history }) => {
  const [redirect] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10);
  const referActive =
    storeConfiguration?.asam_easymsso?.general?.enabled &&
    parseInt(storeConfiguration.asam_easymsso.general.enabled, 10);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(counter => counter - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let interval;
    if (!redirect) {
      interval = setTimeout(() => {
        history.push('/');
      }, 10000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <I18n>
      {t => (
        <Container>
          <Breadcrumbs breadcrumbs={[{ name: t('customerAccount.logOut'), urlPath: '#' }]} />
          <div className={styles.LogoutContainer}>
            <div className={styles.LogoutTitle}>
              <h2>{t('logout.header')}</h2>
              <p>{t('logout.title', { timeLeft })}</p>
            </div>
            {referActive ? (
              <div className={styles.LogoutImage}>
                <div className={styles.LogoutImageBox}>
                  <p>{t('logout.imageTitle')}</p>
                  <p>{t('logout.imageSubTitle')}</p>
                </div>
                <Button to={t('cms.recruit.link')} asLink shadow>
                  {t('logout.buttonTitle')}
                </Button>
              </div>
            ) : null}
          </div>
        </Container>
      )}
    </I18n>
  );
});
