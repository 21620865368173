import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '@deity/falcon-data';

export const SIGN_UP_MUTATION = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input)
  }
`;

export const CHECKOUT_SIGN_UP_MUTATION = gql`
  mutation CheckoutSignUp($input: CheckoutSignUpInput!) {
    checkoutSignUp(input: $input)
  }
`;

export class SignUpMutation extends Mutation {
  static defaultProps = {
    mutation: SIGN_UP_MUTATION,
    refetchQueries: ['Customer', 'Cart']
  };
}

export const useSignUpMutation = (options = {}) =>
  useMutation(SIGN_UP_MUTATION, {
    refetchQueries: ['Customer', 'Cart'],
    ...options
  });

export const useCheckoutSignUpMutation = (options = {}) =>
  useMutation(CHECKOUT_SIGN_UP_MUTATION, {
    refetchQueries: ['Customer'],
    awaitRefetchQueries: true,
    ...options
  });
