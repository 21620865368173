import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '@deity/falcon-data';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export class ResetPasswordMutation extends Mutation {
  static defaultProps = {
    mutation: RESET_PASSWORD_MUTATION
  };
}

export const useResetPasswordMutation = (options = {}) =>
  useMutation(RESET_PASSWORD_MUTATION, {
    ...options
  });
