import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Radio } from '../../../ui/Radio/Radio';
import { CountryListQueryExt } from '../../../overrides/CountryPicker/CountryListQueryExt';
import styles from './AddressSuggestions.module.scss';

export const AddressSuggestionsList = ({ suggestions, name, onSelect, on, setShowSuggestion }) => {
  return on && suggestions && suggestions.length && !suggestions.find(address => address.matchedInput) ? (
    <CountryListQueryExt passLoading>
      {({ data: { countryList } }) => {
        return (
          <div className={styles.AddressSuggestions} data-inspect="AddressSuggestion">
            <div className={[styles.AddressSuggestionsIcon, 'icon-alert-circle'].join(' ')} />
            <div className={styles.AddressSuggestionsTitle}>
              <T id="addressSuggestions.title" />
            </div>
            {suggestions.map((item, index) => {
              let regionCode = '';

              if (item.regionId > 0) {
                let regions = [];

                countryList.items.map(country => {
                  if (country.code === item.countryId) {
                    regions = country.regions; //eslint-disable-line
                  }

                  return country;
                });

                const suggestedRegion = regions.filter(region => parseInt(region.id, 10) === item.regionId);
                regionCode = suggestedRegion[0] && suggestedRegion[0].code;
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`suggestion-${index}`} className={styles.AddressSuggestionsRadio}>
                  <Radio
                    thinLabel
                    name="address"
                    value={`address${name}${index}`}
                    label={`${item.street ? `${item.street}, ` : ''}${item.postcode} ${item.city}${
                      regionCode ? `, ${regionCode}` : ''
                    }`}
                    onChange={e => {
                      if (e.target.checked) {
                        onSelect(item);
                        setTimeout(() => {
                          setShowSuggestion(false);
                        }, 2000);
                      }
                    }}
                  />
                </div>
              );
            })}
            <div className={styles.AddressSuggestionsRadio}>
              <Radio
                thinLabel
                name="address"
                value={`initial-address${name}`}
                label={<T id="addressSuggestions.confirmValue" />}
                onChange={e => {
                  if (e.target.checked) {
                    setTimeout(() => {
                      setShowSuggestion(false);
                    }, 1500);
                  }
                }}
              />
            </div>
          </div>
        );
      }}
    </CountryListQueryExt>
  ) : null;
};
