import React from 'react';
import { Formik } from 'formik';
import { useGetUserError } from '@deity/falcon-data';
import { useResetPasswordMutation } from './ForgotPasswordFormMutation';

export const ForgotPasswordFormProvider = props => {
  const { onSuccess, onRestartProcess, initialValues, ...formikProps } = props;
  const defaultInitialValues = {
    password: ''
  };

  const [resetPassword] = useResetPasswordMutation();
  const [getUserError] = useGetUserError();

  return (
    <Formik
      initialValues={initialValues || defaultInitialValues}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        setStatus();
        resetPassword({ variables: { input: { password: values.password } } })
          .then(({ data }) => {
            if (data.resetPassword === true) {
              setSubmitting(false);
              return onSuccess && onSuccess();
            }

            // On false we will just need to restart the process as the tocken is not ok anymore
            onRestartProcess();
          })
          .catch(e => {
            const error = getUserError(e);
            if (error.length) {
              setStatus({ error });
              setSubmitting(false);
            }
          });
      }}
      {...formikProps}
    />
  );
};
