import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

export const GET_ADDRESS_SUGGESTIONS = gql`
  query AddressSuggestions($city: String, $countryId: String, $regionId: Int, $street: String, $postcode: String) {
    addressSuggestions(city: $city, countryId: $countryId, regionId: $regionId, street: $street, postcode: $postcode) {
      street
      postcode
      city
      countryId
      regionId
      streetOrigin
      matchedInput
    }
  }
`;

export class AddressSuggestionsQuery extends Query {
  static defaultProps = {
    query: GET_ADDRESS_SUGGESTIONS
  };
}

export const useLazyAddressSuggestionsQuery = (options = {}) =>
  useLazyQuery(GET_ADDRESS_SUGGESTIONS, {
    ...options
  });
