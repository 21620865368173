import React from 'react';
import { I18n, T } from '@deity/falcon-i18n';
import { Form } from 'formik';
import { ErrorSummary, FormSubmit } from '@deity/falcon-ui-kit';
import Cookies from 'js-cookie';
import Container from '../../../layout/Container/Container';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { Benefits } from '../../../sections/Customer/Benefits';
import { RegisterForm } from '../SignUp/RegisterForm';
import { FormField } from '../../../ui/Forms/FormField';
import { Button } from '../../../ui/Button/Button';
import { useGlobalStateValue } from '../../../helpers/GlobalState/GlobalState';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './ForgotPassword.module.scss';
import { ForgotPasswordFormProvider } from './ForgotPasswordFormProvider';

const ForgotPasswordForm = withStoreConfiguration(({ storeConfiguration, history }) => {
  const [, dispatch] = useGlobalStateValue();
  const minPassword = storeConfiguration?.customer?.password?.minimum_password_length;
  const minChars = storeConfiguration?.customer?.password?.required_character_classes_number;

  const showNotification = () => {
    window.scrollBy(0, -10000);
    dispatch({
      type: 'ADD_TO_STATE',
      section: 'messages',
      data: {
        content: <T id="signIn.forgotPasswordResetSuccess" />,
        type: 'success',
        animate: false
      }
    });
  };

  const redirect = () => {
    history.push('/');
  };

  const restartProcess = () => {
    Cookies.set('userError', '1', { expires: 1 });
    history.push('/forgot-password');
  };

  const validate = values => {
    const errors = {};
    const pass = values.password.trim();
    const rePass = values.confirmPassword.trim();
    let counter = 0;
    if (pass.match(/\d+/)) {
      counter++;
    }
    if (pass.match(/[a-z]+/)) {
      counter++;
    }
    if (pass.match(/[A-Z]+/)) {
      counter++;
    }
    if (pass.match(/[^a-zA-Z0-9]+/)) {
      counter++;
    }
    if (pass.length < minPassword) {
      errors.password = <T id="formError.toShort" min={minPassword} />;
    } else if (counter < minChars) {
      errors.password = <T id="formError.notSafe" min={minChars} />;
    }
    if (pass !== rePass) {
      errors.confirmPassword = <T id="formError.notEqual" />;
    }
    return errors;
  };

  return (
    <I18n>
      {t => (
        <Container>
          <Breadcrumbs breadcrumbs={[{ name: t('signIn.breadcrumb'), urlPath: '#' }]} />
          <div className={styles.ForgotPassword}>
            <div className={styles.ForgotPasswordWrapper}>
              <div className={styles.ForgotPasswordFrame}>
                <div className={styles.ForgotPasswordHeader}>
                  <h4>
                    <T id="signIn.passwordReset" />
                  </h4>
                </div>

                <ForgotPasswordFormProvider
                  initialValues={{ password: '' }}
                  onSuccess={() => {
                    return showNotification();
                  }}
                  onRestartProcess={() => {
                    return restartProcess();
                  }}
                  validate={validate}
                >
                  {({ isSubmitting, status = {}, submitCount, isValid }) => {
                    const { error } = status;
                    const submitSucceed = isValid && !isSubmitting && submitCount > 0 && !error;

                    return (
                      <Form id="forgotPasswordForm">
                        <div className={styles.ForgotPasswordInput}>
                          <FormField
                            required
                            name="password"
                            type="password"
                            showErrorMessage
                            label={t('signIn.newPassword')}
                            placeholder={t('signIn.newPassword')}
                            variant="secondary"
                          />
                        </div>
                        <div className={styles.ForgotPasswordInput}>
                          <FormField
                            required
                            name="confirmPassword"
                            type="password"
                            showErrorMessage
                            label={t('signIn.newPasswordConfirm')}
                            placeholder={t('signIn.newPasswordConfirm')}
                            variant="secondary"
                          />
                        </div>
                        <div className={styles.ForgotPasswordButtonWrapper}>
                          {status.error && <ErrorSummary errors={status.error} />}
                          <FormSubmit>
                            {({ form }) => (
                              <Button type="submit" size="small" loading={form.isSubmitting}>
                                <T id="signIn.passwordResetButton" />
                              </Button>
                            )}
                          </FormSubmit>
                        </div>
                        {submitSucceed && redirect()}
                      </Form>
                    );
                  }}
                </ForgotPasswordFormProvider>
              </div>
              <Benefits />
            </div>
            <div className={styles.AccountRegister}>
              <RegisterForm />
            </div>
          </div>
        </Container>
      )}
    </I18n>
  );
});
export default ForgotPasswordForm;
