import React, { useEffect, useState } from 'react';
import { STREET_REGEX } from '../../../helpers/Validators/Validators';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { AddressSuggestionsQuery } from './AddressSuggestionsQuery';
import { AddressSuggestionsList } from './AddressSuggestionsList';

export const AddressSuggestions = withStoreConfiguration(({ storeConfiguration, address, name, onSelect }) => {
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [addressPostcode, setAddressPostcode] = useState(null);
  const [addressCity, setAddressCity] = useState(null);
  const [addressStreet, setAddressStreet] = useState(null);
  const [addressSuggestion, setAddressSuggestion] = useState(false);
  const streetValid = STREET_REGEX.test(address.street.trim());
  const checkAddress = storeConfiguration?.asam_addresscheck?.general?.address_validation === '1' || false;

  if (!checkAddress) {
    return null;
  }

  const allowCountry = storeConfiguration?.asam_addresscheck?.general?.allowed_countries?.split(',') || [];

  useEffect(() => {
    setTimeout(() => {
      setShowSuggestion(true);
    }, 700);
  }, [address.street, address.postcode, address.city, address.regionId, address.countryId]);

  if (streetValid && address.postcode !== '' && address.city !== '') {
    setTimeout(() => {
      setAddressSuggestion(true);
      setAddressPostcode(address.postcode);
      setAddressCity(address.city);
      setAddressStreet(address.street);
    }, 700);
  }

  if (
    addressSuggestion &&
    (address.street !== addressStreet || address.postcode !== addressPostcode || address.city !== addressCity)
  ) {
    setAddressSuggestion(false);
  }

  if (address && allowCountry.includes(address.countryId)) {
    return (
      addressSuggestion && (
        <AddressSuggestionsQuery
          passLoading
          variables={{
            city: address.city,
            postcode: address.postcode,
            regionId: address.regionId,
            countryId: address.countryId,
            street: address.street
          }}
        >
          {({ data: { addressSuggestions } }) => {
            return (
              <React.Fragment>
                <AddressSuggestionsList
                  on={showSuggestion}
                  setShowSuggestion={setShowSuggestion}
                  suggestions={addressSuggestions}
                  name={name}
                  onSelect={onSelect}
                />
              </React.Fragment>
            );
          }}
        </AddressSuggestionsQuery>
      )
    );
  }
  return null;
});
