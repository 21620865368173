import React from 'react';
import { Dropdown } from '../../ui/Dropdown/Dropdown';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';

export const RegionPicker = withStoreConfiguration(
  ({
    storeConfiguration,
    countryList,
    onChange,
    selectedRegion,
    selectedCountry,
    title,
    withMargin,
    invalid,
    t,
    inspect,
    ...restProps
  }) => {
    if (storeConfiguration?.general?.region?.state_required && countryList) {
      let regions = [];
      if (storeConfiguration.general.region.state_required.split(',').includes(selectedCountry)) {
        countryList.items.map(item => {
          if (item.code === selectedCountry) {
            regions = item.regions; //eslint-disable-line
          }

          return item;
        });

        const regionsOptions = regions.map(item => {
          return {
            value: parseInt(item.id, 10),
            label: item.name
          };
        });

        regionsOptions.sort((itemA, itemB) => (itemA.label > itemB.label ? 1 : -1));
        regionsOptions.unshift({
          value: 0,
          label: t('selectRegion')
        });

        return (
          <Dropdown
            options={regionsOptions}
            preSelected={selectedRegion}
            title={title}
            onChange={selectedRegionId => {
              onChange(selectedRegionId);
            }}
            {...restProps}
            listFullWidth
            withMargin
            invalid={invalid}
            inspect={inspect}
          />
        );
      }
    }
    return null;
  }
);
