import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const EMAIL_VALIDATOR = gql`
  mutation EmailValidator($email: String!) {
    emailValidator(email: $email)
  }
`;

export const useEmailValidatorMutation = (options = {}) =>
  useMutation(EMAIL_VALIDATOR, {
    ...options
  });
