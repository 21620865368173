import React, { useState } from 'react';
import { T, I18n } from '@deity/falcon-i18n';
import { Form, Field, ErrorMessage } from 'formik';
import { FormSubmit } from '@deity/falcon-ui-kit';
import { useGetUserError } from '@deity/falcon-data';
import { withRouter } from 'react-router-dom';
import { FormField } from '../../../ui/Forms/FormField';
import { Button } from '../../../ui/Button/Button';
import { CountryPicker } from '../../../overrides/CountryPicker/CountryPicker';
import { Checkbox } from '../../../ui/Checkbox/Checkbox';
import { AddressSuggestions } from '../../Customer/AddressSuggestions/AddressSuggestions';
import {
  regionValidator,
  streetValidator,
  fieldsValidator,
  streetNumberValidator
} from '../../../helpers/Validators/Validators';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { useEmailValidatorMutation } from '../../../helpers/EmailValidation/EmailValidationMutation';
import { Errors } from '../../Errors/Errors';
import { Radio } from '../../../ui/Radio/Radio';
import { AmazonLoginButton as LoginWithAmazonButtonV2 } from '../../Amazon/PayV2/AmazonLoginButton';
import { FacebookLoginButton } from '../../SocialConnect/FacebookLoginButton';
import { AppleIdLoginButton } from '../../SocialConnect/AppleId/AppleIdLoginButton';
import { RegionPicker } from '../../../overrides/CountryPicker/RegionPicker';
import { CountryListQueryExt } from '../../../overrides/CountryPicker/CountryListQueryExt';
import { useLazyCustomerExistCheckQuery } from '../CustomerExistCheck/CustomerExistCheckMutation';
import { SignInForm } from '../SignIn/SignInForm';
import additionalStyles from '../../../pages/Checkout/LoginStep/LoginStep.module.scss';
import { ZipListQuery } from '../../../helpers/ZipValidation/ZipListQuery';
import { RegisterTracking } from '../../Tracking/GoogleTagManager';
import { useGlobalStateValue } from '../../../helpers/GlobalState/GlobalState';
import { getSuffixByUrl } from '../../LanguageSwitcher/AutoLanguageSwitcher/AutoLanguageSwitch';
import loadable from '../../../../../../components/loadable';
import { PaypalLoginButton } from '../../SocialConnect/PayPal/PaypalLoginButton';
import { SetAddProductToWishlistMutation } from '../../Wishlist/WishlistMutation';
import styles from './Register.module.scss';
import { SignUpFormProvider } from './SignUpFormProvider';
import { useSignUpMutation } from './SignUpMutation';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../../ui/Modal/Modal'));

export const RegisterForm = withRouter(
  withStoreConfiguration(({ storeConfiguration, history, location }) => {
    const [, dispatch] = useGlobalStateValue();
    const [emailValidator] = useEmailValidatorMutation();
    const [getUserError] = useGetUserError();
    const [signUp] = useSignUpMutation();
    const defaultCountry = storeConfiguration?.general?.country?.default;
    const prefixOptions = storeConfiguration?.customer?.address?.prefix_options?.split(';');
    const requiredStates = storeConfiguration?.general?.region?.state_required;
    const requiredPhone = storeConfiguration?.asam_customer?.address?.required_phonenumber_countries?.split(',');
    const phoneTooltip = storeConfiguration?.asam_customer?.address?.phonenumber_tooltip;
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showFullLoginPopup, setShowFullLoginPopup] = useState(false);
    const [showAgbOverlay, setShowAgbOverlay] = useState(false);
    const [showDataProtectionOverlay, setShowDataProtectionOverlay] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);

    const facebookButton = storeConfiguration?.asam_socialconnect?.enabled;
    const amazonButton = storeConfiguration?.payment?.amazon_payment_v2?.active === '1';
    const [warningMessage, setWarningMessage] = useState(null);

    const showNotification = () => {
      window.scrollBy(0, -10000);
      dispatch({
        type: 'ADD_TO_STATE',
        section: 'messages',
        data: {
          content: <T id="signUp.success" />,
          type: 'success',
          animate: false
        }
      });
    };

    // search parameters for subscription redirect in case user do a quick registration
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    const next = queryParams.get('next') || '/';
    const toWishlist = queryParams.get('toWishlist');
    const aboInterval = queryParams.get('aboInterval') ? parseInt(queryParams.get('aboInterval'), 10) : 0;
    const aboQuantity = queryParams.get('aboQuantity');
    const aboAllowedCountries = ['DE', 'AT', 'CH']; // TODO: add these somewhere in the subscriptions Magento settings
    const isProduct = queryParams.get('isProduct');

    const aboRedirect = () => {
      if (aboInterval) {
        const url = new URL(next, window.location.origin);
        url.searchParams.append('aboInterval', aboInterval);
        url.searchParams.append('aboQuantity', aboQuantity);
        history.replace(url.pathname + url.search);
      }
    };

    const LoginPopupContent = initialMail => (
      <React.Fragment>
        <div className={additionalStyles.LoginPopupHeader}>
          {forgotPassword ? <T id="checkout.forgotPasswordPopupHeader" /> : <T id="checkout.loginPopupHeader" />}
        </div>
        {forgotPassword ? (
          <div className={additionalStyles.LoginPopupText}>
            <T id="checkout.forgotPasswordPopupText" />
          </div>
        ) : null}
        <SignInForm
          initialEmail={initialMail.initialMail}
          id="RegisterPopUpFormLogin"
          lwaId="RegisterPopUpFormLogin"
          variant="secondary"
          onSuccess={() => {
            if (next && aboInterval && aboInterval) {
              aboRedirect();
            } else if (next && isProduct) {
              history.replace(`${next}?notify-popup`);
            } else {
              window.location = `${window.location.origin}/${getSuffixByUrl(window.location.pathname)}/account`.replace(
                '//account',
                '/account'
              );
            }
          }}
          forgotPassword={forgotPassword}
          setForgotPassword={setForgotPassword}
          withoutSocial
          inPopup
          onForgotSuccess={() => {
            setShowFullLoginPopup(false);
            setShowLoginPopup(false);
            setForgotPassword(false);
          }}
        />
      </React.Fragment>
    );

    const LoginFullPopupContent = initialMail => (
      <React.Fragment>
        <div className={additionalStyles.LoginPopupHeader}>
          {forgotPassword ? <T id="checkout.forgotPasswordPopupHeader" /> : <T id="checkout.existLoginPopupHeader" />}
        </div>
        {forgotPassword ? (
          <div className={additionalStyles.LoginPopupText}>
            <T id="checkout.forgotPasswordPopupText" />
          </div>
        ) : null}
        <SignInForm
          initialEmail={initialMail.initialMail}
          id="RegisterFormPopUpFullLogin"
          lwaId="RegisterFormPopUpFullLogin"
          variant="secondary"
          onSuccess={() => {
            if (next && aboInterval && aboInterval) {
              aboRedirect();
            } else if (next && isProduct) {
              history.replace(`${next}?notify-popup`);
            } else {
              window.location = `${window.location.origin}/${getSuffixByUrl(window.location.pathname)}/account`.replace(
                '//account',
                '/account'
              );
            }
          }}
          inPopup
          forgotPassword={forgotPassword}
          setForgotPassword={setForgotPassword}
          onForgotSuccess={() => {
            setShowFullLoginPopup(false);
            setShowLoginPopup(false);
          }}
        />
      </React.Fragment>
    );

    const [customerExistCheck] = useLazyCustomerExistCheckQuery({
      onCompleted: ({ customerExistCheck: customerExist }) => {
        if (customerExist) {
          setShowFullLoginPopup(true);
        }
      }
    });

    const customerExist = values => {
      if (values.email && values.email !== '' && values.email.match(/(.+)@(.+)\.([a-zA-Z]{2,})/)) {
        customerExistCheck({ variables: { email: values.email } });
      }
    };

    const agbLabel = (
      <I18n>
        {t => (
          <span className="normal">
            {t('signUp.registerLabel')}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span onClick={() => setShowAgbOverlay(true)}>{t('signUp.agb')}</span>
            {t('signUp.registerLabelOne')}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span onClick={() => setShowDataProtectionOverlay(true)}>{t('signUp.privacyPolicy')}</span>
            {t('signUp.registerLabelTwo')}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span onClick={() => setShowDataProtectionOverlay(true)}>{t('signUp.dataProtection')}</span>.
          </span>
        )}
      </I18n>
    );

    return (
      <I18n>
        {t => (
          <div>
            <ZipListQuery>
              {({ data: { zipList } }) => (
                <div className={styles.RegisterForm}>
                  <SetAddProductToWishlistMutation>
                    {setAddProductToWishlist => (
                      <SignUpFormProvider
                        initialValues={{
                          defaultBilling: true,
                          defaultShipping: true,
                          email: '',
                          firstname: '',
                          lastname: '',
                          prefix: prefixOptions[0],
                          postcode: '',
                          street: '',
                          company: '',
                          city: '',
                          telephone: '',
                          countryId: defaultCountry,
                          regionId: 0,
                          password: '',
                          confirmPassword: '',
                          autoSignIn: true,
                          is_subscribed: false,
                          terms: false
                        }}
                        onSubmit={(values, { setSubmitting, setStatus }) => {
                          emailValidator({
                            variables: { email: values.email }
                          })
                            .then(() => {
                              signUp({ variables: { input: values } })
                                .then(() => {
                                  setSubmitting(false);
                                  showNotification();
                                  RegisterTracking({ storeConfiguration });

                                  if (toWishlist) {
                                    setAddProductToWishlist({
                                      variables: { productId: toWishlist }
                                    }).then(() => {
                                      history.replace(next);

                                      if (next) {
                                        history.push({
                                          search: '?show-popup'
                                        });
                                      }
                                    });
                                  } else if (
                                    aboAllowedCountries.includes(values.countryId) &&
                                    next &&
                                    aboInterval &&
                                    aboInterval
                                  ) {
                                    aboRedirect();
                                  }
                                })
                                .catch(e => {
                                  const error = getUserError(e);
                                  if (error.length) {
                                    setStatus({ error });
                                    setSubmitting(false);
                                    RegisterTracking({
                                      storeConfiguration,
                                      failed: true,
                                      errorMessage:
                                        error !== undefined && error[0] !== undefined ? error[0].message : ''
                                    });
                                  }
                                });
                            })
                            .catch(e => {
                              if (e) {
                                setSubmitting(false);
                                setStatus({
                                  error: [{ message: t('emailValidator.error'), code: 'INTERNAL_SERVER_ERROR' }]
                                });
                                RegisterTracking({
                                  storeConfiguration,
                                  failed: true,
                                  errorMessage: t('emailValidator.error')
                                });
                              }
                            });
                        }}
                        validate={
                          values => fieldsValidator(customerExist(values), values, zipList.list, t, storeConfiguration)
                          //
                        }
                      >
                        {({ status = {}, values, setFieldValue }) => (
                          <React.Fragment>
                            <Form id="signUp">
                              <div className={styles.NewCustomerHeadline}>
                                <T id="customerAccount.newCustomer" />
                              </div>
                              <FormField name="prefix" required className={styles.RegisterFormRadioWrapper}>
                                {({ form, field }) => {
                                  return prefixOptions.map(item => (
                                    <Radio
                                      key={item}
                                      name="namePrefix"
                                      value={item}
                                      checked={field.value === item}
                                      onChange={x => form.setFieldValue(field.name, x.target.value)}
                                      label={t(`prefix.${item}`)}
                                    />
                                  ));
                                }}
                              </FormField>
                              <FormField
                                name="firstname"
                                type="text"
                                showErrorMessage
                                required
                                className={styles.RegisterFormInput}
                                noMargin
                                label={t('signUp.firstname')}
                                placeholder={t('signUp.firstname')}
                              />
                              <FormField
                                className={styles.RegisterFormInput}
                                name="lastname"
                                type="text"
                                showErrorMessage
                                required
                                noMargin
                                label={t('signUp.lastname')}
                                placeholder={t('signUp.lastname')}
                              />
                              <FormField
                                className={styles.RegisterFormInput}
                                name="street"
                                type="text"
                                showErrorMessage
                                required
                                noMargin
                                validate={[streetValidator]}
                                label={t('signUp.street')}
                                placeholder={t('signUp.street')}
                                autoComplete="off"
                                onChange={x => {
                                  setFieldValue(x.target.name, x.target.value);
                                  if (
                                    streetValidator({ value: x.target.value }) === undefined &&
                                    streetNumberValidator(x.target.value)
                                  ) {
                                    setWarningMessage(t('formError.missingHouseNr'));
                                  } else {
                                    setWarningMessage(null);
                                  }
                                }}
                                showWarningMessage={warningMessage}
                              />
                              <FormField
                                className={styles.RegisterFormInput}
                                name="company"
                                type="text"
                                showErrorMessage
                                noMargin
                                label={t('signUp.company')}
                                placeholder={t('signUp.company')}
                              />
                              <div className={styles.RegisterFormInputWrapper}>
                                <div className={styles.RegisterFormInputPostcode}>
                                  <FormField
                                    className={styles.RegisterFormInput}
                                    name="postcode"
                                    type="text"
                                    showErrorMessage
                                    required
                                    noMargin
                                    label={t('signUp.postcode')}
                                    placeholder={t('signUp.postcode')}
                                  />
                                </div>
                                <div className={styles.RegisterFormInputCity}>
                                  <FormField
                                    className={styles.RegisterFormInput}
                                    name="city"
                                    type="text"
                                    showErrorMessage
                                    required
                                    noMargin
                                    label={t('signUp.city')}
                                    placeholder={t('signUp.city')}
                                  />
                                </div>
                              </div>
                              <AddressSuggestions
                                address={{
                                  street: values.street,
                                  postcode: values.postcode,
                                  city: values.city,
                                  regionId: values.regionId,
                                  countryId: values.countryId
                                }}
                                name="book"
                                onSelect={addr => {
                                  if ('street' in addr) {
                                    setFieldValue('street', addr.street);
                                  }
                                  if ('postcode' in addr) {
                                    setFieldValue('postcode', addr.postcode);
                                  }
                                  if ('city' in addr) {
                                    setFieldValue('city', addr.city);
                                  }
                                  if ('regionId' in addr) {
                                    setFieldValue('regionId', addr.regionId);
                                  }
                                }}
                              />

                              {requiredPhone && requiredPhone.includes(values.countryId) ? (
                                <FormField
                                  className={styles.RegisterFormInput}
                                  name="telephone"
                                  type="tel"
                                  showErrorMessage
                                  noMargin
                                  label={t('signUp.telephone')}
                                  placeholder={t('signUp.telephone')}
                                  tooltipMessage={phoneTooltip}
                                  required
                                />
                              ) : (
                                <FormField
                                  className={styles.RegisterFormInput}
                                  name="telephone"
                                  type="tel"
                                  showErrorMessage
                                  noMargin
                                  label={t('signUp.telephone')}
                                  placeholder={t('signUp.telephone')}
                                  tooltipMessage={phoneTooltip}
                                />
                              )}
                              <CountryListQueryExt passLoading>
                                {({ data: { countryList } }) => {
                                  const isRegionRequired = requiredStates.split(',').includes(values.countryId);
                                  return (
                                    <React.Fragment>
                                      <div className={styles.RegisterFormInput}>
                                        <FormField name="countryId">
                                          {({ form, field }) =>
                                            countryList ? (
                                              <CountryPicker
                                                {...field}
                                                options={countryList.items}
                                                onChange={x => {
                                                  form.setFieldValue(field.name, x);
                                                  form.setFieldValue('regionId', 0);
                                                }}
                                                selectedCountry={defaultCountry}
                                                inspect="SignUpCountrySelect"
                                              />
                                            ) : null
                                          }
                                        </FormField>
                                      </div>
                                      <FormField name="regionId" validate={isRegionRequired ? [regionValidator] : []}>
                                        {({ form, field }) => {
                                          return (
                                            <RegionPicker
                                              {...field}
                                              countryList={countryList}
                                              onChange={x => {
                                                form.setFieldValue(field.name, x);
                                              }}
                                              selectedRegion={field.value}
                                              selectedCountry={form.values.countryId}
                                              t={t}
                                              invalid={'regionId' in form.errors}
                                              inspect="SignUpRegionSelect"
                                            />
                                          );
                                        }}
                                      </FormField>
                                    </React.Fragment>
                                  );
                                }}
                              </CountryListQueryExt>
                              <FormField
                                className={styles.RegisterFormInput}
                                name="email"
                                type="email"
                                showErrorMessage
                                required
                                noMargin
                                label={t('signUp.email')}
                                placeholder={t('signUp.email')}
                              />
                              <div className={styles.RegisterFormInputPassword}>
                                <FormField
                                  className={styles.RegisterFormInput}
                                  name="password"
                                  type="password"
                                  autoComplete="off"
                                  required
                                  showErrorMessage
                                  noMargin
                                  label={t('signUp.password')}
                                  placeholder={t('signUp.password')}
                                />
                              </div>
                              <div className={styles.RegisterFormInputConfirmPassword}>
                                <FormField
                                  className={styles.RegisterFormInput}
                                  name="confirmPassword"
                                  type="password"
                                  autoComplete="off"
                                  required
                                  showErrorMessage
                                  noMargin
                                  label={t('signUp.confirmPassword')}
                                  placeholder={t('signUp.confirmPassword')}
                                />
                              </div>
                              <div className={styles.RegisterFormAgreementsWrapper}>
                                <div className={styles.RegisterFormTerms}>
                                  <div className={styles.RegisterFormRequired}>
                                    <ErrorMessage name="terms" />
                                  </div>
                                  <Field
                                    required
                                    name="terms"
                                    render={({ field }) => {
                                      return (
                                        <Checkbox
                                          name="terms"
                                          id="terms"
                                          buttonOnTop
                                          type="checkbox"
                                          checked={field.value.terms}
                                          label={agbLabel}
                                          {...field}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                                {storeConfiguration?.newsletter?.general?.active === '1' && (
                                  <Field
                                    render={({ field }) => {
                                      return (
                                        <Checkbox
                                          name="is_subscribed"
                                          id="is_subscribed"
                                          type="checkbox"
                                          buttonOnTop
                                          checked={field.value.is_subscribed}
                                          label={<span className="normal">{t('signUp.newsletterLabel')}</span>}
                                          {...field}
                                        />
                                      );
                                    }}
                                  />
                                )}
                              </div>

                              <div className={styles.RegisterFormButtonsWrapper}>
                                {status.error && <Errors error={status.error} />}
                                <FormSubmit>
                                  {({ form }) => (
                                    <Button
                                      type="submit"
                                      size="small"
                                      data-inspect="register-button"
                                      loading={form.isSubmitting}
                                      onClick={() => customerExistCheck({ variables: { email: values.email } })}
                                    >
                                      <T id="signUp.register" />
                                    </Button>
                                  )}
                                </FormSubmit>
                              </div>
                            </Form>
                            <Modal
                              content={<LoginPopupContent initialMail={values.email} />}
                              variant="centered"
                              visible={showLoginPopup}
                              close={() => {
                                setShowLoginPopup(false);
                                setForgotPassword(false);
                              }}
                              showCloseLabel
                            />
                            <Modal
                              content={<LoginFullPopupContent initialMail={values.email} />}
                              variant="centered"
                              visible={showFullLoginPopup}
                              close={() => {
                                setShowFullLoginPopup(false);
                                setForgotPassword(false);
                              }}
                              showCloseLabel
                            />
                          </React.Fragment>
                        )}
                      </SignUpFormProvider>
                    )}
                  </SetAddProductToWishlistMutation>
                  <div className={styles.RegisterFormButtonsWrapper}>
                    {amazonButton || facebookButton ? (
                      <span className="LabelWithDashesText">
                        <T id="customerAccount.or" />
                      </span>
                    ) : null}
                    <div>
                      <FacebookLoginButton />
                      <AppleIdLoginButton />
                      <LoginWithAmazonButtonV2 placement="Other" />
                      <PaypalLoginButton />
                    </div>
                  </div>
                </div>
              )}
            </ZipListQuery>
            {showAgbOverlay ? (
              <Modal
                pageContent={t('cms.agb.link')}
                variant="tertiary"
                scrollableContent
                visible={showAgbOverlay}
                close={() => {
                  setShowAgbOverlay(false);
                }}
              />
            ) : null}
            {showDataProtectionOverlay ? (
              <Modal
                pageContent={t('cms.dataprotection.link')}
                variant="tertiary"
                scrollableContent
                visible={showDataProtectionOverlay}
                close={() => {
                  setShowDataProtectionOverlay(false);
                }}
              />
            ) : null}
          </div>
        )}
      </I18n>
    );
  })
);
