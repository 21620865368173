import React, { useEffect } from 'react';
import { I18n, T } from '@deity/falcon-i18n';
import { Form } from 'formik';
import { Link } from 'react-router-dom';
import { ErrorSummary, FormSubmit } from '@deity/falcon-ui-kit';
import { Helmet } from 'react-helmet-async';
import Cookies from 'js-cookie';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import Container from '../../../layout/Container/Container';
import { FormField } from '../../../ui/Forms/FormField';
import { Button } from '../../../ui/Button/Button';
import { Benefits } from '../../../sections/Customer/Benefits';
import { RegisterForm } from '../SignUp/RegisterForm';
import { useGlobalStateValue } from '../../../helpers/GlobalState/GlobalState';
import { ForgotPasswordPath } from './ForgotPasswordPath';
import styles from './ForgotPassword.module.scss';
import { ForgotPaswordProvider } from './ForgotPasswordProvider';
import { useRequestPasswordResetMutation } from './ForgotPasswordMutation';

export default ({ history }) => {
  const [requestPasswordReset] = useRequestPasswordResetMutation();
  const [, dispatch] = useGlobalStateValue();
  const checkError = !!parseInt(Cookies.get('userError'), 10);

  useEffect(() => {
    if (checkError) {
      window.scrollBy(0, -10000);
      dispatch({
        type: 'ADD_TO_STATE',
        section: 'messages',
        data: {
          content: <T id="signIn.forgotPasswordError" />,
          type: 'error',
          animate: false
        }
      });

      // reset cookie
      Cookies.remove('userError');
    }
  }, [checkError]);

  const redirect = () => {
    history.push('/');
  };

  return (
    <I18n>
      {t => (
        <Container>
          <Helmet>
            <title>{t('signIn.forgotPasswordSeoTitle')}</title>
          </Helmet>
          <Breadcrumbs breadcrumbs={[{ name: t('signIn.breadcrumb'), urlPath: '/forgot-password' }]} />
          <div className={styles.ForgotPassword}>
            <div className={styles.ForgotPasswordWrapper}>
              <div className={styles.ForgotPasswordFrame}>
                <div className={styles.ForgotPasswordHeader}>
                  <h4>
                    <T id="signIn.passwordReset" />
                  </h4>
                </div>
                <p className={styles.ForgotPasswordText}>
                  <T id="signIn.forgotPasswordHeader" />
                </p>
                <ForgotPaswordProvider
                  initialValues={{ email: '', template: 'email_reset', websiteId: 1 }}
                  validateOnBlur={false}
                  onSubmit={(values, { setSubmitting }) =>
                    requestPasswordReset({ variables: { input: values } }).then(() => {
                      let email = null;
                      let message = null;
                      // eslint-disable-next-line prefer-destructuring
                      email = values.email;
                      message = {
                        content: t('signIn.forgotPasswordMessage', { email }),
                        type: 'success',
                        animate: false
                      };
                      dispatch({
                        type: 'ADD_TO_STATE',
                        section: 'messages',
                        data: message
                      });
                      setSubmitting(false);
                    })
                  }
                >
                  {({ isSubmitting, status = {}, submitCount, isValid }) => {
                    const { error } = status;
                    const submitSucceed = isValid && !isSubmitting && submitCount > 0 && !error;

                    return (
                      <Form id="forgotPassword">
                        <div className={styles.ForgotPasswordInput}>
                          <FormField
                            required
                            name="email"
                            type="email"
                            showErrorMessage
                            label={t('signUp.email')}
                            placeholder={t('signUp.email')}
                            variant="secondary"
                          />
                        </div>
                        <div className={styles.ForgotPasswordButtonWrapper}>
                          {status.error && <ErrorSummary errors={status.error} />}
                          <FormSubmit>
                            {({ form }) => (
                              <Button type="submit" size="small" loading={form.isSubmitting}>
                                <T id="signIn.buttonSend" />
                              </Button>
                            )}
                          </FormSubmit>
                          <Link className="Link LinkPurple" to={ForgotPasswordPath.backToLogin.to}>
                            <T id="signIn.buttonBack" />
                          </Link>
                        </div>
                        {submitSucceed && redirect()}
                      </Form>
                    );
                  }}
                </ForgotPaswordProvider>
              </div>
              <Benefits />
            </div>
            <div className={styles.AccountRegister}>
              <RegisterForm />
            </div>
          </div>
        </Container>
      )}
    </I18n>
  );
};
