import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { NotFound } from '@deity/falcon-ui-kit';
import loadable from '../../../../../components/loadable';
import { GoogleTagManager4Pageview } from '../../elements/Tracking/GoogleTagManager';
import { AccountPath } from './AccountPath';

const AccountDashboard = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Dashboard/Dashboard'));
const Orders = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Orders/Orders'));
const Order = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Orders/Order'));
const Subscriptions = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Subscriptions/Subscriptions'));
const Wishlist = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Wishlist/Wishlist'));
const RateProducts = loadable(() => import(/* webpackChunkName: "customerAccount" */ './RateProducts/RateProducts'));
const Profile = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Profile/Profile'));
const ProfileEdit = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Profile/PersonalInformation'));
const ChangePassword = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Profile/ChangePassword'));
const ChangeEmail = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Profile/ChangeEmail'));
const AddressBook = loadable(() =>
  import(/* webpackChunkName: "customerAccount" */ '../../../../../pages/shop/Account/AddressBook/AddressBook')
);
const AddAddress = loadable(() =>
  import(/* webpackChunkName: "customerAccount" */ '../../../../../pages/shop/Account/AddressBook/AddAddress')
);
const EditAddress = loadable(() =>
  import(/* webpackChunkName: "customerAccount" */ '../../../../../pages/shop/Account/AddressBook/EditAddress')
);
const Help = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Help/Help'));
const AccountNewsletter = loadable(() => import(/* webpackChunkName: "customerAccount" */ './Newsletter/Newsletter'));
const AccountSubscriberInfo = loadable(() =>
  import(/* webpackChunkName: "customerAccount" */ './Newsletter/NewsletterSubscriberInfo')
);

const Account = () => {
  const accountLocation = useLocation();

  return (
    <React.Fragment>
      <GoogleTagManager4Pageview
        dataLayerData={{
          page_type: accountLocation.pathname.indexOf('wishlist') !== -1 ? 'wishlist' : 'account',
          page_title: document?.querySelector('title')?.text
        }}
      />
      <Switch>
        <Route exact path={AccountPath.dashboard.to} component={AccountDashboard} />
        <Route exact path={AccountPath.orders.to} component={Orders} />
        <Route exact path={AccountPath.orderDetails.to} component={Order} />
        <Route exact path={AccountPath.subscriptions.to} component={Subscriptions} />
        <Route exact path={AccountPath.wishlist.to} component={Wishlist} />
        <Route exact path={AccountPath.rating.to} component={RateProducts} />
        <Route exact path={AccountPath.profile.to} component={Profile} />
        <Route exact path={AccountPath.profileEdit.to} component={ProfileEdit} />
        <Route exact path={AccountPath.changePassword.to} component={ChangePassword} />
        <Route exact path={AccountPath.changeEmail.to} component={ChangeEmail} />
        <Route exact path={AccountPath.addressBook.to} component={AddressBook} />
        <Route exact path={AccountPath.addressBookAdd.to} component={AddAddress} />
        <Route exact path={AccountPath.addressBookEdit.to} component={EditAddress} />
        <Route exact path={AccountPath.help.to} component={Help} />
        <Route exact path={AccountPath.newsletter.to} component={AccountNewsletter} />
        <Route exact path={AccountPath.subscriberInfo.to} component={AccountSubscriberInfo} />
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Account;
