import { useEffect } from 'react';
import storesConfig from '../../../../../../storesConfig';
import { useSetStoreMutation } from '../StoreMutation';

const getSuffixByLocale = locale => {
  const config = storesConfig().storeViewsMap.find(item => item.language === locale);

  if (config) {
    return config.suffix;
  }

  return storesConfig().defaultSuffix;
};

export const getSuffixByUrl = currentUrl => {
  const storeViewsConfig = storesConfig();
  let basename = storeViewsConfig.defaultSuffix;

  storeViewsConfig.storeViewsMap.forEach(item => {
    if (currentUrl === `/${item.suffix}` || currentUrl.indexOf(`/${item.suffix}/`) === 0) {
      basename = item.suffix;
    }
  });

  return basename;
};

const getStoreViewCodeBySuffix = suffix => {
  const storeViewsConfig = storesConfig();
  let storeViewCode = storeViewsConfig.defaultStoreView;

  storeViewsConfig.storeViewsMap.forEach(item => {
    if (item.suffix === suffix) {
      storeViewCode = item.storeView;
    }
  });

  return storeViewCode;
};

export const AutoLanguageSwitch = ({ sessionLocale, i18n, showLoader }) => {
  const [setStoreMutation] = useSetStoreMutation();

  useEffect(() => {
    const suffixFromSession = getSuffixByLocale(sessionLocale);
    const suffixFromUrl = getSuffixByUrl(window.location.pathname);

    if (suffixFromSession !== suffixFromUrl) {
      const storeViewCode = getStoreViewCodeBySuffix(suffixFromUrl);

      setStoreMutation({ variables: { storeCode: storeViewCode } }).then(({ data }) => {
        showLoader(true);
        i18n.changeLanguage(data.setShopStore.activeLocale);

        window.location.reload();
      });
    }
  }, []);

  return null;
};
