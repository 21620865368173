import gql from 'graphql-tag';
import { Query } from '@deity/falcon-data';
import { useLazyQuery } from '@apollo/react-hooks';

export const CUSTOMER_EXIST_CHECK = gql`
  query CustomerExistCheck($email: String!) {
    customerExistCheck(email: $email)
  }
`;

export class CustomerExistCheckQuery extends Query {
  static defaultProps = {
    query: CUSTOMER_EXIST_CHECK
  };
}

export const useLazyCustomerExistCheckQuery = (options = {}) =>
  useLazyQuery(CUSTOMER_EXIST_CHECK, {
    ...options
  });
