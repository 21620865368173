import React from 'react';
import { I18n, T } from '@deity/falcon-i18n';
import { Helmet } from 'react-helmet-async';
import qs from 'qs';
import { Breadcrumbs } from '../../elements/Breadcrumbs/Breadcrumbs';
import Container from '../../layout/Container/Container';
import { SignInForm } from '../../elements/CustomerAccount/SignIn/SignInForm';
import { Benefits } from '../../sections/Customer/Benefits';
import { RegisterForm } from '../../elements/CustomerAccount/SignUp/RegisterForm';
import { SetAddProductToWishlistMutation } from '../../elements/Wishlist/WishlistMutation';
import { Errors } from '../../elements/Errors/Errors';
import styles from './SignIn.module.scss';

const SignIn = ({ history, location }) => {
  const { search } = location;

  const queryParams = new URLSearchParams(search);
  const next = queryParams.get('next') || '/';
  const toWishlist = queryParams.get('toWishlist');
  const aboInterval = queryParams.get('aboInterval') ? parseInt(queryParams.get('aboInterval'), 10) : 0;
  const aboQuantity = queryParams.get('aboQuantity');
  const isProduct = queryParams.get('isProduct');

  const handleSuccess = (mutation, { data: { signIn } }) => {
    if (toWishlist && signIn && signIn.customerId) {
      mutation({ variables: { productId: toWishlist, customerId: signIn.customerId } }).then(() => {
        history.replace(next);

        if (next) {
          history.push({
            search: '?show-popup'
          });
        }
      });
    } else if (aboInterval) {
      const url = new URL(next, window.location.origin);
      url.searchParams.append('aboInterval', aboInterval);
      url.searchParams.append('aboQuantity', aboQuantity);
      history.replace(url.pathname + url.search);
    } else if (next && isProduct) {
      history.replace(`${next}?notify-popup`);
    } else {
      history.replace('/account');
    }
  };

  /* Errors handling */
  const errorMessages = [];
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (params && params.error_reason === 'user_denied') {
    const errorMessageFacebook = [];
    errorMessageFacebook.message = <T id="generalErrors.facebookUserDenied" />;
    errorMessages.push(errorMessageFacebook);
  }

  return (
    <I18n>
      {t => (
        <Container>
          <Helmet>
            <title>{t('signIn.seoTitle')}</title>
          </Helmet>
          <Breadcrumbs breadcrumbs={[{ name: t('signIn.breadcrumb'), urlPath: '/sign-in' }]} />
          <div className={styles.Account}>
            <div className={styles.AccountLogin}>
              {errorMessages ? <Errors error={errorMessages} additionalClasses={['ErrorMessageBox']} /> : null}
              <div className={styles.AccountFrame}>
                <div className={styles.AccountLoginHeader}>
                  <div className={styles.NewCustomerHeadline}>
                    <T id="customerAccount.signIn" />
                  </div>
                </div>

                {/* TODO: recheck here if abo can go with addToWishlist handler */}
                <SetAddProductToWishlistMutation>
                  {setAddProductToWishlist => (
                    <SignInForm
                      id="signIn"
                      variant="secondary"
                      onSuccess={result => handleSuccess(setAddProductToWishlist, result)}
                      lwaId="sign-in"
                    />
                  )}
                </SetAddProductToWishlistMutation>
              </div>
              <Benefits />
            </div>
            <div className={styles.AccountRegister}>
              <RegisterForm />
            </div>
          </div>
        </Container>
      )}
    </I18n>
  );
};

export default SignIn;
