import gql from 'graphql-tag';
import { Mutation } from '@deity/falcon-data';
import { useMutation } from '@apollo/react-hooks';

export const SET_STORE = gql`
  mutation SetStore($storeCode: String!) {
    setShopStore(storeCode: $storeCode) {
      activeLocale
      shop {
        activeStore
      }
    }
  }
`;

export const SET_STORE_VIEW_BY_STORE = gql`
  mutation SetStoreViewByStore($storeCode: String!, $activeLocale: String!) {
    setStoreViewByStore(storeCode: $storeCode, activeLocale: $activeLocale) {
      activeLocale
      shop {
        activeStore
      }
    }
  }
`;

export class SetStoreMutation extends Mutation {
  static defaultProps = {
    refetchQueries: ['BackendConfig', 'ProductsInWishlist', 'AddressList', 'MegaMenu'],
    awaitRefetchQueries: true,
    mutation: SET_STORE
  };
}

export const useSetStoreMutation = (options = {}) =>
  useMutation(SET_STORE, {
    refetchQueries: ['BackendConfig', 'ProductsInWishlist', 'AddressList', 'MegaMenu'],
    awaitRefetchQueries: true,
    ...options
  });

export class SetStoreViewByStoreMutation extends Mutation {
  static defaultProps = {
    mutation: SET_STORE_VIEW_BY_STORE
  };
}
